import 'core-js/features/url';
import getConfig from 'next/config';
import NextImage, { ImageProps } from 'next/legacy/image';
import React, { FC, memo } from 'react';

type Props = ImageProps & {
  src: string;
};

// The next/image Image component cannot optimize images whose src domains are not
// included in the image.domains list in next.config.js and will throw an error.
// This component provides a fallback in case we receive an unexpected domain.
// There's an open issue requesting ability to use wildcard domains or automatically
// fallback to <img /> here: https://github.com/vercel/next.js/issues/18632
const Image: FC<Props> = (props) => {
  const { alt, src, width, height } = props;

  const srcUrl = new URL(src);
  const { hostname } = srcUrl;
  const { imageDomains } = getConfig().publicRuntimeConfig;

  if (!imageDomains.includes(hostname)) {
    return (
      <img
        alt={alt}
        src={src}
        width={width}
        height={height}
        loading="lazy"
        onError={(event: React.ChangeEvent<HTMLImageElement>) =>
          (event.target.style.display = 'none')
        }
      />
    );
  }

  return (
    <NextImage
      width={width}
      height={height}
      {...props}
      onError={(event: React.ChangeEvent<HTMLImageElement>) =>
        (event.target.style.display = 'none')
      }
    />
  );
};

export default memo(Image);
